import { Box, Typography, useTheme } from "@mui/material";
import Colleague from "../../components/Colleague";
import WidgetWrapper from "../../components/WidgetWrapper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setColleagues } from "../../state/auth";
import { createAxiosPrivate } from "../../api/axios";

const ColleagueListWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const user_id = useSelector((state) => state.auth.user_id);
  const reactor_id = useSelector((state) => state.auth.reactorId);
  const colleagues = useSelector((state) => state.auth.colleagues);
  const colleagues_endpoint = `/user/colleagues/${reactor_id}/${user_id}`;
  const axiosPrivate = createAxiosPrivate();

  const getColleagues = async () => {
    const response = await axiosPrivate.get(
      colleagues_endpoint, {}
    );
    dispatch(setColleagues(response.data.data));
  };

  useEffect(() => {
    getColleagues();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Colleague List
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {colleagues.map((colleague) => (
          <Colleague
            key={colleague.id}
            colleagueId={colleague.id}
            name={`${colleague.username}`}
            subtitle={colleague.fullname}
          />
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default ColleagueListWidget;