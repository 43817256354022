import { useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import { useTranslation } from 'react-i18next';

const DateTimeToggleButton = ({ showDateTimePicker, onToggleDateTimePicker }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
  
    return (
      <Button
        onClick={onToggleDateTimePicker}
        sx={{
          "&:disabled": {
            backgroundColor: colors.primary[200],
            color: colors.primary[500],
          },
          color: colors.greenAccent[500],
          bgcolor: colors.greenAccent[100],
          borderRadius: "3rem",
          "&:hover": {
            fontWeight: "bold",
          },
        }}
      >
        {showDateTimePicker ? t('dateTimeToggleButton.hideTime') : t('dateTimeToggleButton.setTime')}
      </Button>
    );
  };

export default DateTimeToggleButton
