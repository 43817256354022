import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Modal,
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RecyclingIcon from "@mui/icons-material/Recycling";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { setEvents } from "../state/reactor";
import styled from "styled-components";
import { tokens } from "../theme";
import ResponsiveBox from "./ResponsiveBox";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { validateNumericInputWeight } from "../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";

const StyledCard = styled(Card)`
  cursor: pointer;
  transition: transform 0.15s ease-in-out;
  position: relative;
  border-radius: 1em;
  &:hover {
    transform: scale(1.03);
  }
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: ${({ "data-canharvest": canharvest }) =>
    canharvest === "true" ? "4px solid rgba(118, 212, 53, 0.75)" : "none"};
`;

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background-color: #f5f5f5;
  border-radius: 0.25em;
  margin-right: 0.5em;
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(Box)`
  padding: 1.25em;
  border-radius: 1em;
  outline: none;
  margin-top: ${({ mt }) => mt || "0px"};
`;

const HarvestDataTile = ({
  suggestedHarvestData,
  totalHarvestedData,
  unit,
  onUpdate,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [weight, setWeight] = useState(suggestedHarvestData);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const canharvest = suggestedHarvestData > 0;
  const axiosPrivate = useAxiosPrivate();
  const reactorId = useSelector((state) => state.auth.reactorId);
  const [errors, setErrors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleHarvestNow = async () => {
    let weightValidationErrors = "";
    weightValidationErrors += validateNumericInputWeight({
        inputValue: weight,
        inputName: "Weight",
        minValue: 1,
        maxValue: 2000,
      });
    if (weightValidationErrors.length > 0) {
      setErrors(weightValidationErrors);
      setSnackbarMessage(weightValidationErrors);
      setSnackbarOpen(true);
      return;
    }
    const content = {};

    content.weight = Number(weight);

    const event = {
      name: "Quick Harvest",
      content: content,
      type_name: "harvest",
    };

    try {
      const response = await axiosPrivate.post(`/events/${reactorId}/add`, {
        event,
      });
      if (onUpdate) onUpdate();
      dispatch(setEvents(response.data.data));
      setWeight("");
      setOpen(false);
    } catch (error) {
      const errorMessage = "Error submitting event";
      setErrors(errorMessage);
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      setOpen(false);
      return;
    }
  };


  return (
    <>
      <StyledCard onClick={handleOpen} data-canharvest={canharvest.toString()}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("harvestDataTile.harvest")}
          </Typography>
          <ResponsiveBox>
            <Grid
              container
              spacing={2}
              style={{ marginTop: 8, alignItems: "center" }}
            >
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <IconBox>
                    <FileUploadIcon style={{ color: "#ffc107" }} />
                  </IconBox>
                  <Box>
                    <Typography variant="body1" component="div">
                      {suggestedHarvestData}
                      {unit}
                    </Typography>
                    <Typography variant="caption" component="div">
                      {t("harvestDataTile.suggestedHarvest")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" alignItems="center">
                  <IconBox>
                    <RecyclingIcon style={{ color: "#4caf50" }} />
                  </IconBox>
                  <Box>
                    <Typography variant="body1" component="div">
                      {totalHarvestedData}
                      {unit}
                    </Typography>
                    <Typography variant="caption" component="div">
                      {t("harvestDataTile.totalHarvested")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ResponsiveBox>
          <IconButton
            style={{ position: "absolute", top: "0.5em", right: "0.5em" }}
            onClick={handleOpen}
          >
            <ArrowForwardIosIcon
              style={{ color: "#9e9e9e" }}
              fontSize="small"
            />
          </IconButton>
        </CardContent>
      </StyledCard>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent
          sx={{
            bgcolor: colors.primary[400],
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("harvestDataTile.harvest")}
          </Typography>
          <TextField
            type="number"
            label={t("harvestDataTile.harvestAmount")}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{ endAdornment: unit }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleHarvestNow}
          >
            {t("harvestDataTile.harvestNow")}
          </Button>
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default HarvestDataTile;
