import { createSlice } from "@reduxjs/toolkit";

const reactorInitialState = {
  events: [],
  stats: [],
};

export const reactorSlice = createSlice({
  name: "reactor",
  initialState: reactorInitialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload.events;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setEvent: (state, action) => {
      const updatedEvents = state.events.map((event) => {
        if (event.id === action.payload.event.id) {
          return action.payload.event;
        }
        return event;
      });
      state.events = updatedEvents;
    },
    resetReactorState: () => reactorInitialState,
  },
});

export const { setEvents, setEvent, setStats, resetReactorState } = reactorSlice.actions;

export default reactorSlice.reducer;