import * as React from 'react';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { Paper, Typography } from "@mui/material";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: box-shadow 0.3s;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border: ${({ level, max }) => level > max ? '2px solid rgba(244, 67, 54, 0.75)' : '4px solid rgba(76, 175, 80, 0.75)'};
`;

const ReactorFillGauge = ({ min, max, level, unit }) => {
  const unitFontSize = '01.3em';
  const levelColor = level > max ? '#e53935' : '#52b202';

  return (
    <StyledPaper elevation={3} level={level} max={max}>
      <Gauge
        valueMin={min}
        valueMax={max}
        width={200}
        height={200}
        value={level}
        cornerRadius="50%"
        sx={(theme) => ({
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: '2.2em',
          },
          [`& .${gaugeClasses.valueArc}`]: {
            fill: levelColor,
          },
          [`& .${gaugeClasses.referenceArc}`]: {
            fill: theme.palette.text.disabled,
          },
        })}
      />
      <Typography style={{
        position: 'absolute',
        fontSize: unitFontSize,
        bottom: '25%',
      }}>
        {unit}
      </Typography>
      </StyledPaper>
  );
};

export default ReactorFillGauge;
