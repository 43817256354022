import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// MUI imports
import { Box, Typography, Select, MenuItem } from "@mui/material";

// Custom components and utilities
import WidgetWrapper from "../../components/WidgetWrapper";
import { setEvents } from "../../state/reactor";
import { createAxiosEvent } from "../../api/axios";
import useAxiosInterceptor from "../../hooks/useAxiosIntercepter";
import { validateNumericInputWeight } from "../../utils/validation";
import { useTranslation } from 'react-i18next';

// Event Forms
import {
  FeedEventForm,
  GenericEventForm,
  HarvestEventForm,
} from "../../components/events/forms";

const MyEventWidget = () => {
  const { t, i18n } = useTranslation();
  const axiosEvent = createAxiosEvent();
  const dispatch = useDispatch();
  const [isFile, setIsFile] = useState(false);
  const [file, setFile] = useState("");
  const [eventName, setEventName] = useState("");
  const [eventType, setEventType] = useState("feed");
  const reactorId = useSelector((state) => state.auth.reactorId);
  const [weight, setWeight] = useState("");
  const [smell, setSmell] = useState("");
  const [liquidity, setLiquidity] = useState("");
  const [homogeneity, setHomogeneity] = useState("");
  const [smellDescription, setSmellDescription] = useState("");
  const [liquidityDescription, setLiquidityDescription] = useState("");
  const [homogeneityDescription, setHomogeneityDescription] = useState("");
  const [recirculated, setRecirculated] = useState("");
  const [errors, setErrors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const axiosEventClient = useAxiosInterceptor(axiosEvent);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("Feed");
  const [tags, setTags] = useState([]);
  const [englishTags, setEnglishTags] = useState([]);

  useEffect(() => {
    const initialSmellDescriptionKey = `smellDropDown.${smell}.description`;
    const initialSmellDescription = i18n.getFixedT('en')(initialSmellDescriptionKey);
    setSmellDescription(initialSmellDescription);

    const initialLiquidityDescriptionKey = `liquidityDropDown.${liquidity}.description`;
    const initialLiquidityDescription = i18n.getFixedT('en')(initialLiquidityDescriptionKey);
    setLiquidityDescription(initialLiquidityDescription);

    const initialHomogeneityDescriptionKey = `homogeneityDropDown.${homogeneity}.description`;
    const initialHomogeneityDescription = i18n.getFixedT('en')(initialHomogeneityDescriptionKey);
    setLiquidityDescription(initialHomogeneityDescription);
  }, [smell, liquidity, homogeneity, i18n]);

  const eventTypes = [
    { value: 'Feed', label: t('eventTypes.feed') },
    { value: 'Harvest', label: t('eventTypes.harvest') },
    { value: 'Generic', label: t('eventTypes.generic') },
  ];

  const handleEventTypeChange = (event) => {
    setSelectedEventType(event.target.value);
    setEventType(event.target.value.toLowerCase());
  };

  const handleEventDetailsChange = (newDetails) => {
    console.log("triggered");
    const { name, weight, recirculated, smell, smellDescription, liquidity, liquidityDescription, homogeneity, homogeneityDescription } = newDetails;
    setEventName(name);
    setWeight(weight);
    setRecirculated(recirculated);
    setSmell(smell);
    setSmellDescription(smellDescription);
    setLiquidity(liquidity);
    setLiquidityDescription(liquidityDescription);
    setHomogeneity(homogeneity);
    setHomogeneityDescription(homogeneityDescription);
  };

  const handleEvent = async () => {
    if (dateError) {
      setSnackbarMessage(dateError);
      setSnackbarOpen(true);
      return;
    }
    setShowDateTimePicker(false);
    setSelectedDateTime(new Date());
    let weightValidationErrors = "";
    const eventDateTimeUTC = selectedDateTime.toISOString();
    if (eventType !== "generic") {
      weightValidationErrors += validateNumericInputWeight({
        inputValue: weight,
        inputName: "Weight",
        minValue: 1,
        maxValue: 2000,
        t,
      });
    }
    if (eventType === "feed") {
      weightValidationErrors += validateNumericInputWeight({
        inputValue: recirculated,
        inputName: "Recirculated",
        minValue: 0,
        maxValue: 500,
        t,
      });
    }
    if (weightValidationErrors.length > 0) {
      setErrors(weightValidationErrors);
      setSnackbarMessage(weightValidationErrors);
      setSnackbarOpen(true);
      return;
    }
    const content = {};

    if (eventType === "feed") {
      content.recirculated = Number(recirculated);
      content.tags = englishTags.map(tag => tag.label);
    }

    if (eventType === "harvest") {
      content.smell = { value: smell, description: smellDescription };
      content.liquidity = { value: liquidity, description: liquidityDescription };
      content.homogeneity = { value: homogeneity, description: homogeneityDescription };
    }

    if (eventType !== "generic") {
      content.weight = Number(weight);
    }

    const event = {
      name: eventName,
      content: content,
      type_name: eventType,
    };

    const formData = new FormData();
    formData.append("name", eventName);
    formData.append("type_name", eventType);
    formData.append("content", JSON.stringify(content));

    if (showDateTimePicker) {
      formData.append("created_at", eventDateTimeUTC);
    }

    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await axiosEventClient.post(
        `/${reactorId}/add`,
        formData,
        {}
      );
      dispatch(setEvents(response.data.data));
      setEventName("");
      setSelectedDateTime(new Date());
      setShowDateTimePicker(false);
      setFile("");
      setWeight("");
      setRecirculated("");
      setTags([]);
      setEnglishTags([]); 
    } catch (error) {
      const errorMessage = "Error submitting event";
      setErrors(errorMessage);
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      return;
    }
  };

  const handleDateTimeChange = (newValue) => {
    const now = new Date();
    if (newValue > now) {
      setDateError("Date cannot be in the future.");
    } else {
      setDateError("");
      setSelectedDateTime(newValue);
    }
  };

  const handleFileChange = (acceptedFiles) => {
    if (acceptedFiles === null) {
      setFile(null);
    } else if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const toggleIsFile = () => {
    setIsFile(!isFile);
  };

  const toggleDateTimePicker = () => {
    setShowDateTimePicker(!showDateTimePicker);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSmellChange = (value, description) => {
    setSmell(value);
    setSmellDescription(description);
  };

  const handleLiquidityChange = (value, description) => {
    setLiquidity(value);
    setLiquidityDescription(description);
  };

  const handleHomogeneityChange = (value, description) => {
    setHomogeneity(value);
    setHomogeneityDescription(description);
  }


  return (
    <WidgetWrapper>
      {/* Event Type Selector */}
      <Box mb={2}>
        <Typography variant="h6">{t('selectEventType')}</Typography>
        <Select
          value={selectedEventType}
          onChange={handleEventTypeChange}
          displayEmpty
          inputProps={{ "aria-label": t('eventType') }}
        >
          <MenuItem value=""><em>{t('none')}</em></MenuItem>
          {eventTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
          ))}
        </Select>
      </Box>

      {/* Conditionally Render Based on Event Type */}
      {/* Feed event form */}
      {selectedEventType === "Feed" && (
        <FeedEventForm
          feedName={eventName}
          weight={weight}
          recirculated={recirculated}
          onEventDetailsChange={handleEventDetailsChange}
          onFeedNameChange={(e) => {
            setEventName(e.target.value);
            handleEventDetailsChange({
              name: e.target.value,
              weight,
              recirculated,
            });
          }}
          onWeightChange={(e) => setWeight(e.target.value)}
          onRecirculatedChange={(e) => setRecirculated(e.target.value)}
          isFile={isFile}
          onFileChange={handleFileChange}
          file={file}
          setIsFile={toggleIsFile}
          showDateTimePicker={showDateTimePicker}
          selectedDateTime={selectedDateTime}
          onDateTimeChange={handleDateTimeChange}
          dateTimeError={dateError}
          onToggleDateTimePicker={toggleDateTimePicker}
          handleEvent={handleEvent}
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
          tags={tags}
          setTags={setTags}
          englishTags={englishTags}
          setEnglishTags={setEnglishTags}
        />
      )}
      {/* Harvest event form */}
      {selectedEventType === "Harvest" && (
        <HarvestEventForm
          harvestName={eventName}
          weight={weight}
          smell={smell}
          liquidity={liquidity}
          homogeneity={homogeneity}
          onEventDetailsChange={handleEventDetailsChange}
          onHarvestNameChange={(e) => {
            setEventName(e.target.value);
            handleEventDetailsChange({
              name: e.target.value,
              weight,
              smell,
              smellDescription,
              liquidity,
              liquidityDescription,
              homogeneity,
              homogeneityDescription,
            });
          }}
          onWeightChange={(e) => setWeight(e.target.value)}
          onSmellChange={handleSmellChange}
          onLiquidityChange={handleLiquidityChange}
          onHomogeneityChange={handleHomogeneityChange}
          isFile={isFile}
          onFileChange={handleFileChange}
          file={file}
          setIsFile={toggleIsFile}
          showDateTimePicker={showDateTimePicker}
          selectedDateTime={selectedDateTime}
          onDateTimeChange={handleDateTimeChange}
          dateTimeError={dateError}
          onToggleDateTimePicker={toggleDateTimePicker}
          handleEvent={handleEvent}
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
        />
      )}
      {/* Generic event form */}
      {selectedEventType === "Generic" && (
        <GenericEventForm
          genericEventName={eventName}
          onEventDetailsChange={handleEventDetailsChange}
          onGenericEventNameChange={(e) => {
            setEventName(e.target.value);
            handleEventDetailsChange({
              name: e.target.value,
            });
          }}
          isFile={isFile}
          onFileChange={handleFileChange}
          file={file}
          setIsFile={toggleIsFile}
          showDateTimePicker={showDateTimePicker}
          selectedDateTime={selectedDateTime}
          onDateTimeChange={handleDateTimeChange}
          dateTimeError={dateError}
          onToggleDateTimePicker={toggleDateTimePicker}
          handleEvent={handleEvent}
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          snackbarMessage={snackbarMessage}
        />
      )}
    </WidgetWrapper>
  );
};

export default MyEventWidget;
