import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "./Header";
import { useTranslation } from 'react-i18next';

const Missing = () => {
  const { t } = useTranslation();
  return (
    <Box m="20px">
    <Header
      title={t('missing.oops')}
      subtitle={t('missing.pageNotFound')} 
    />
    <Box height="75vh">
    <Link to="/">{t('missing.visitHomepage')}</Link>
    </Box>
  </Box>
  );
};

export default Missing;
