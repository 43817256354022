export function toPascalCase(inputString) {
  return inputString
    .split(/\s|_/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
}

export function convertToLocalTime(utcDate) {
  const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
  return localDate;
}

export function formatRelativeTime(dateString, t) {
  /*
  Formats datetime into a relative timestamp whilst also
  converting from UTC to the users local timezone.
  */
  const date = new Date(dateString);
  const now = new Date();
  const localDate = convertToLocalTime(now);
  const seconds = Math.round((localDate - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);

  if (seconds < 60) return t("formatting.justNow");
  else if (minutes < 60) return t("formatting.minutesAgo", { count: minutes });
  else if (hours < 24) return t("formatting.hoursAgo", { count: hours });
  else if (days < 7) return t("formatting.daysAgo", { count: days });
  else return date.toLocaleDateString();
}

function padTwoDigits(num) {
  return num.toString().padStart(2, "0");
}


export function formatISO8601Timestamp(dateString, dateDiveder = "-") {
  /*
  Formats datetime into ISO8601 timestamp whilst also
  converting from UTC to the users local timezone.
  Smallest unit is seconds
  */
  const date = new Date(dateString);
    return (
      [
        date.getFullYear(),
        padTwoDigits(date.getMonth() + 1),
        padTwoDigits(date.getDate()),
      ].join(dateDiveder) +
      " " +
      [
        padTwoDigits(date.getHours()),
        padTwoDigits(date.getMinutes()),
        padTwoDigits(date.getSeconds()),
      ].join(":")
    );
  };