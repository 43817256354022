import { io } from "socket.io-client";

export function getSocket(subscriptionDetails = {}) {
  const WebsocketURL = window.appConfig.REACT_APP_API_URL;
  const appURL = window.appConfig.PUBLIC_URL;

  // Convert subscription details to query parameters
  const queryParams = new URLSearchParams(subscriptionDetails).toString();

  return io(`${WebsocketURL}?${queryParams}`, {
    autoConnect: true,
    withCredentials: true,
    transports: ["websocket"],
    cors: {
      origin: appURL,
    },
  });
}
