import { useEffect, useState, useRef } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { getSocket } from "../api/socket";

const useWebSocketInterceptor = (subscriptionDetails) => {
  const { auth } = useAuth();
  const refresh = useRefreshToken();
  const [socket, setSocket] = useState(null);

  const initializeSocket = (reactorId) => {
    const newSocket = getSocket({ reactorId });

    newSocket.on("connect", () => {
      console.log("WebSocket connected");
      newSocket.emit("subscribe", { reactorId });
    });

    newSocket.on("message", (data) => {
      if (data.entity) {
        // Handle incoming WebSocket messages
      } else {
        console.warn("Received data without 'entity' field:", data);
      }
    });

    newSocket.on("disconnect", async (reason) => {
      console.log("WebSocket disconnected: ", reason);
      if (reason === "io server disconnect" || reason === "transport close") {
        const tokenRefreshed = await refresh();
        if (tokenRefreshed) {
          initializeSocket(reactorId);
        }
      }
    });

    setSocket(newSocket);
  };

  useEffect(() => {
    if (!socket) {
      initializeSocket(subscriptionDetails.reactorId);
    }

    return () => {
      if (socket) {
        socket.emit("client-disconnect");
        socket.close();
        console.log("WebSocket disconnected by client");
      }
    };
  }, [subscriptionDetails.reactorId]);

  // Subscribe to reactor specific data
  const subscribe = async (reactorId) => {
    const tokenRefreshed = await refresh();
    if (tokenRefreshed) {
      socket.emit("subscribe", { reactorId });
    } else {
      console.error("Failed to refresh token");
    }
  };

  return { socket, subscribe, initializeSocket };
};

export default useWebSocketInterceptor;
