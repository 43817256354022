import { InputBase } from "@mui/material";

const NumericInputField = ({ label, placeholder, value, onChange, sx }) => {
    return (
      <InputBase
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type="number"
        sx={sx}
      />
    );
  };

export default NumericInputField;
