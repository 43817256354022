import React, { useState, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { validatePassword } from "../utils/validation";
import Swal from "sweetalert2";
import { createAxiosPublic } from "../api/axios";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from 'react-i18next';

export function Reset({ buttonLabel, endpoint }) {
  const [showMessageError1, setShowMessageError1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [showMessageError2, setShowMessageError2] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const [redirect, setRedirect] = useState(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get("token");
  const axiosPublic = createAxiosPublic();
  const { t } = useTranslation();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const handlePasswordChange = (setter) => (e) => {
  //   setter(e.target.value);
  //   setErrMsg("");
  // };

  // const toggleVisibility = (setter) => () => setter((prev) => !prev);

  function toggleVisibility1() {
    setShowPassword1(!showPassword1);
  }
  function toggleVisibility2() {
    setShowPassword2(!showPassword2);
  }
  const handlePassword1Change = (e) => {
    setPassword1(e.target.value);
    setErrMsg("");
  };
  const handlePassword2Change = (e) => {
    setPassword2(e.target.value);
    setErrMsg("");
  };

  const onClickSubmit = async () => {
    const error1 = validatePassword(password1, t);
    const error2 = password1 !== password2 ? t('errors.passwordMismatch') : "";
  
    if (!error1 && !error2) {
      try {
        const response = await axiosPublic.put(
          `${endpoint}`,
          { password: password1, token },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
  
        if (response.data.status === "success") {
          Swal.fire({
            position: "center",
            icon: "success",
            titleText: t('reset.successMessage'),
            showConfirmButton: false,
            timer: 9000,
          });
          setRedirect(true);
        } else {
          throw new Error('resetFailed');
        }
      } catch (err) {
        // Handling specific error messages
        if (!err.response) {
          setErrMsg(t('errors.noServerResponse'));
        } else if (err.response?.status === 400) {
          setErrMsg(t('errors.invalidToken'));
        } else {
          setErrMsg(t('errors.resetFailed'));
        }
        errRef.current.focus();
      }
    } else {
      setErrMsg(error1 || error2);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
      <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {buttonLabel}
          </Typography>
          <form onSubmit={onClickSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              label={t('reset.newPasswordLabel')}
              type={showPassword1 ? "text" : "password"}
              autoComplete="off"
              onChange={handlePassword1Change}
              value={password1}
              InputProps={{
                style: {
                  color: colors.greenAccent[600],
                  backgroundColor: colors.primary[400],
                },
              }}
            />
            <Button
              type="button"
              onClick={toggleVisibility1}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "8px 20px",
              }}
            >
              {t('reset.toggleVisability')}
            </Button>
            {showMessageError1 && <div>{errorMessage1}</div>}

            <TextField
              margin="normal"
              required
              fullWidth
              label={t('reset.confirmPasswordLabel')}
              type={showPassword2 ? "text" : "password"}
              autoComplete="off"
              onChange={handlePassword2Change}
              value={password2}
              InputProps={{
                style: {
                  color: colors.greenAccent[600],
                  backgroundColor: colors.primary[400],
                },
              }}
            />
            <Button
              type="button"
              onClick={toggleVisibility2}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "8px 20px",
              }}
            >
              {t('reset.toggleVisability')}
            </Button>
            {showMessageError2 && <div>{errorMessage2}</div>}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                marginTop: "15px",
              }}
            >
              {t('reset.submit')}
            </Button>
            {redirect && <Navigate to="/" />}
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
