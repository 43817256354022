import { createSlice } from "@reduxjs/toolkit";

const authInitialState = {
  mode: "light",
  user_id: null,
  user: [],
  roles: null,
  colleagues: [],
  reactorId: null,
  reactors: [],
  language: "de",
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user_id = action.payload.user_id;
      state.user = action.payload.user;
      state.roles = action.payload.roles.split(",");
      state.language = action.payload.language;
    },
    setLogout: (state) => {
      return authInitialState;
    },
    setLanguage: (state, action) => {
      state.language = action.payload.language;
    },
    setColleagues: (state, action) => {
      state.colleagues = action.payload.colleagues;
    },
    setReactors: (state, action) => {
      if (action.payload){
        state.reactors = action.payload.reactors;
      } else {
        console.error("No reactors found :(");
      }
    },
    setReactor: (state, action) => {
      state.reactorId = action.payload.reactorId;
    },
  },
});

export const {
  setMode,
  setLogin,
  setLogout,
  setLanguage,
  setColleagues,
  setReactors,
  setReactor,
} = authSlice.actions;

export default authSlice.reducer;
