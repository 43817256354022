import React from "react";
import {
  Box,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import FlexBetween from "../../FlexBetween";
import { useTranslation } from 'react-i18next';
import {
  CustomDateTimePicker,
  CustomDivider,
  CustomSnackbar,
  DateTimeToggleButton,
  FileSelector,
  FileToggleButton,
  InputField,
  PostButton,
} from "../ui-elements";

const GenericEventForm = ({
  genericEventName,
  onEventDetailsChange,
  onGenericEventNameChange,
  isFile,
  onFileChange,
  file,
  setIsFile,
  showDateTimePicker,
  selectedDateTime,
  onDateTimeChange,
  dateTimeError,
  onToggleDateTimePicker,
  handleEvent,
  snackbarOpen,
  handleCloseSnackbar,
  snackbarMessage,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <Box>
      {/* Generic Event Name */}
      <Box my={2}>
        <InputField
          placeholder={t('genericEventForm.eventPlaceholder')}
          onChange={onGenericEventNameChange}
          value={genericEventName}
        />
      </Box>

      {/* Conditional Rendering for DateTime Picker */}
      <CustomDateTimePicker
        dateTimeLabel={t('genericEventForm.dateTimeLabel')}
        showDateTimePicker={showDateTimePicker}
        selectedDateTime={selectedDateTime}
        onDateTimeChange={onDateTimeChange}
        dateTimeError={dateTimeError}
      />
      {/* Conditional Rendering for File Selector */}
      <FileSelector
        isFile={isFile}
        onFileChange={onFileChange}
        file={file}
        setIsFile={setIsFile}
      />

      {/* Divider */}
      <CustomDivider />

      {/* Buttons */}
      <FlexBetween>
        {/* File Button */}
        <FileToggleButton isFile={isFile} setIsFile={setIsFile} />
        {/* Toggle Button for DateTimePicker */}
        <DateTimeToggleButton
          showDateTimePicker={showDateTimePicker}
          onToggleDateTimePicker={onToggleDateTimePicker}
        />

        {/* Post Button */}
        <PostButton
          disabled={!genericEventName}
          onClick={handleEvent}
          buttonText={t('genericEventForm.postButton')}
        />
      </FlexBetween>

      {/* Snackbar */}
      <CustomSnackbar
        snackbarOpen={snackbarOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={snackbarMessage}
      />
    </Box>
  );
};

export default GenericEventForm;
