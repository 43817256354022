import React from 'react';
import { Paper, Typography } from '@mui/material';
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1em;
  height: 6rem;
  width: 6rem;
  padding: 0.5em;
  margin-bottom: 1em;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
`;

const StyledIcon = styled.div`
  margin-bottom: 0.1em;
`;

const DataPointTile = ({ data, header, valueUnits, IconComponent }) => {
    const roundedData = parseFloat(data).toFixed(2);

    return (
      <StyledPaper elevation={3}>
        {IconComponent && <StyledIcon><IconComponent fontSize="large" /></StyledIcon>}
        <Typography variant="h6">{header}</Typography>
        <Typography variant="h5">{roundedData}{valueUnits}</Typography>
      </StyledPaper>
    );
};

export default DataPointTile;
