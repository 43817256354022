import { Box, useMediaQuery, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
  // const [user, setUser] = useState(null);
  const { reactorId, userId } = useParams();
  const roles = useSelector((state) => state.auth.roles);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { t } = useTranslation();

  // const getUser = async () => {
  //   const response = await fetch(`http://localhost:5000/api/users/${userId}`, {
  //     method: "GET"
  //   });
  //   const data = await response.json();
  //   setUser(data);
  // };

  // useEffect(() => {
  //   getUser();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if (!user) return null;

  return (
    <Box>
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <Typography
            // color={palette.neutral.dark}
            variant="h5"
            fontWeight="500"
            sx={{ mb: "1.5rem" }}
          >
            {t("profilePage.title")}
          </Typography>
          <Typography
            // color={palette.neutral.dark}
            variant="h5"
            fontWeight="500"
            sx={{ mb: "1.5rem" }}
          >
            {roles} {userId}
          </Typography>
          <Box m="2rem 0" />
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <Box m="2rem 0" />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilePage;
