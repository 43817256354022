import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

/* Fallback component for when user is trying to access unauthorized content */
const Unauthorized = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goBack = () => navigate(-1);

  return (
    <section>
      <h1>{t('unauthorized.title')}</h1>
      <br />
      <p>{t('unauthorized.message')}</p>
      <div className="flexGrow">
        <button onClick={goBack}>{t('unauthorized.goBack')}</button>
      </div>
    </section>
  );
};

export default Unauthorized;
