import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { validateEmail } from "../utils/validation";
import { createAxiosPublic } from "../api/axios";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useTranslation } from 'react-i18next';

export function RequestReset({ buttonLabel }) {
  const [showMessageError1, setShowMessageError1] = useState(false);
  const [errorMessage1, setErrorMessage1] = useState("");
  const [email, setEmail] = useState("");
  const [redirect, setRedirect] = useState(false);
  const endpoint = "/user/reset";
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPublic = createAxiosPublic();
  const { t } = useTranslation();

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const onClickSubmit = async () => {
    const error = validateEmail(email, t);

    setErrorMessage1(error);

    if (!error) {
      setShowMessageError1(false);

      try {
        const response = await axiosPublic.post(
          `${endpoint}`,
          { email: email },
          {}
        );

        const status = response.data.status;

        if (status === "success") {
          Swal.fire({
            position: "center",
            icon: "success",
            titleText: t('requestReset.successMessage'),
            showConfirmButton: false,
            timer: 9000,
          });
          setRedirect(true);
        }
      } catch (err) {
        const errorTitle = err.response.data && err.response.data.data.message ? err.response.data.data.message
            : t('requestReset.errorMessage');
        Swal.fire({
          position: "center",
          icon: "error",
          titleText: errorTitle,
          showConfirmButton: false,
          timer: 5000,
        });
        console.error(err);
        setShowMessageError1(true);
      }
    } else {
      setShowMessageError1(true); // Show the error message
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {buttonLabel}
            {t('requestReset.resetPassword')}
          </Typography>
          <form>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t('requestReset.emailLabel')}
              value={email}
              onChange={handleEmail}
              InputProps={{
                style: {
                  color: "black",
                  backgroundColor: "white",
                },
              }}
            />
            {showMessageError1 && <div>{errorMessage1}</div>}
            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={onClickSubmit}
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              {t('requestReset.submit')}
            </Button>
          </form>
          {redirect && <Navigate to="/" />}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
