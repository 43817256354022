import { formLabelClasses } from "@mui/material";
import { createAxiosPublic } from "../api/axios";
import useAuth from "./useAuth";
import localforage from "localforage";
import { setLogout } from "../state/auth";
import { resetReactorState } from "../state/reactor";
import { useDispatch } from "react-redux";

/* Logout hook that resets Auth and creates request to logout API */
const useLogout = () => {
  const axiosPublic = createAxiosPublic();
  const { setAuth } = useAuth();
  const dispatch = useDispatch();

  const logout = async () => {
    try {
      const response = await axiosPublic.post(
        "/logout",
        {},
        {
          withCredentials: true,
        }
      );
      setAuth({});
      localStorage.clear();
      localStorage.setItem("persist", false);
      localforage.clear();
      sessionStorage.clear();
      dispatch(setLogout());
      dispatch(resetReactorState());
    } catch (err) {
      console.error(err);
    }
  };
  return logout;
};

export default useLogout;
