import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FlexBetween from "../../FlexBetween";

const CustomDateTimePicker = ({
    dateTimeLabel,
    showDateTimePicker,
    selectedDateTime,
    onDateTimeChange,
    dateTimeError
  }) => {
    if (!showDateTimePicker) return null;
  
    return (
      <FlexBetween gap="3rem" mt={2} mb={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label={dateTimeLabel}
            format="dd-MM-yyyy HH:mm:ss"
            value={selectedDateTime}
            onChange={onDateTimeChange}
            slotProps={{ textField: { variant: 'outlined' }}}
            showTime={false}
            error={dateTimeError}
          />
        </LocalizationProvider>
      </FlexBetween>
    );
  };

export default CustomDateTimePicker;
