import { useTheme, Typography } from "@mui/material";
import FlexBetween from "../../FlexBetween";
import { AttachFileOutlined } from "@mui/icons-material";
import { tokens } from "../../../theme";
import { useTranslation } from "react-i18next";

const FileToggleButton = ({ isFile, setIsFile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  return (
    <FlexBetween
      gap="0.3rem"
      sx={{
        margin: "1rem",
        borderRadius: "3rem",
      }}
      onClick={() => setIsFile(!isFile)}
    >
      <AttachFileOutlined sx={{ color: colors.primary[300] }} />
      <Typography
        color={colors.primary[300]}
        sx={{
          color: colors.primary[300],
          "&:hover": { cursor: "pointer", color: colors.primary[100] },
        }}
      >
        {isFile
          ? t("fileToggleButton.hideFile")
          : t("fileToggleButton.addFile")}
      </Typography>
    </FlexBetween>
  );
};

export default FileToggleButton;
