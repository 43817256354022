import { createContext, useState } from "react";

/* Create Reactor object for storing Reactor data */
const ReactorContext = createContext({});

/* Create Reactor component for serving and setting reactor props within ReactorContext */
export const ReactorProvider = ({ children }) => {
  const [reactor, setReactorX] = useState({});

  return (
    <ReactorContext.Provider value={{ reactor, setReactorX}}>
      {children}
    </ReactorContext.Provider>
  );
};

export default ReactorContext;