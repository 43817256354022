import React from 'react';
import { Paper, Typography } from "@mui/material";
import * as d3 from 'd3';
import styled from 'styled-components';

const StyledSVG = styled.svg`
  width: 100%;
  height: 50px;
`;

const CapsuleChart = ({ data, header }) => {
    const [hoverText, setHoverText] = React.useState('');

    const totalWeight = data.reduce((acc, cur) => acc + cur.weight, 0);
    const sortedData = [...data].sort((a, b) => a.timedelta - b.timedelta);

    // Calculate separate min and max for harvestable and not harvestable
    const minMaxHarvestable = {
        min: Math.min(...data.filter(d => d.harvestable).map(d => d.timedelta)),
        max: Math.max(...data.filter(d => d.harvestable).map(d => d.timedelta))
    };
    const minMaxNotHarvestable = {
        min: Math.min(...data.filter(d => !d.harvestable).map(d => d.timedelta)),
        max: Math.max(...data.filter(d => !d.harvestable).map(d => d.timedelta))
    };

    // Define color scales for harvestable and not harvestable
    const colorScaleHarvestable = d3.scaleLinear()
        .domain([minMaxHarvestable.min, minMaxHarvestable.max])
        .range(["#f7e379", "#44ce1b"]);

    const colorScaleNotHarvestable = d3.scaleLinear()
        .domain([minMaxNotHarvestable.min, minMaxNotHarvestable.max])
        .range([ "#e51f1f", "#f2a134"]);

    const getColor = (harvestable, timedelta) => {
        return harvestable ? colorScaleHarvestable(timedelta) : colorScaleNotHarvestable(timedelta);
    };

    let accumulatedWidth = 0;
    const segments = sortedData.map(item => {
        const widthPercent = (item.weight / totalWeight) * 100;
        const segment = { ...item, widthPercent, accumulatedWidth };
        accumulatedWidth += widthPercent;
        return segment;
    });

    return (
        <Paper elevation={3} style={{ borderRadius: '30px', padding: '20px', overflow: 'hidden' }}>
            <Typography variant="h5">{header}</Typography>
            <div style={{ position: 'relative', width: '100%' }}>
                <StyledSVG>
                    {segments.map((segment, index) => (
                        <rect
                            key={index}
                            x={`${segment.accumulatedWidth}%`}
                            y="0"
                            width={`${segment.widthPercent}%`}
                            height="50"
                            fill={getColor(segment.harvestable, segment.timedelta)}
                            onMouseEnter={() => setHoverText(`Time digesting: ${segment.timedelta}hrs, Weight: ${segment.weight}kg, Harvestable: ${segment.harvestable}`)}
                            onMouseLeave={() => setHoverText('')}
                        />
                    ))}
                </StyledSVG>
                <div style={{ position: 'absolute', width: '100%', top: '60px', textAlign: 'center', pointerEvents: 'none' }}>{hoverText}</div>
            </div>
        </Paper>
    );
};

export default CapsuleChart;
