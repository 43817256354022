import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Modal,
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import RecyclingIcon from "@mui/icons-material/Recycling";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { setEvents } from "../state/reactor";
import styled from "styled-components";
import { tokens } from "../theme";
import { useTheme } from "@mui/material/styles";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { validateNumericInputWeight } from "../utils/validation";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const StyledCard = styled(Card)`
  cursor: pointer;
  transition: transform 0.15s ease-in-out;
  position: relative;
  border-radius: 1em;
  &:hover {
    transform: scale(1.03);
  }
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: ${({ "data-canfeed": canfeed }) =>
    canfeed === "true" ? "4px solid rgba(118, 212, 53, 0.75)" : "none"};
`;

const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-right: 8px;
`;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(Box)`
  padding: 20px;
  border-radius: 15px;
  outline: none;
  margin-top: ${({ mt }) => mt || "0px"};
`;

const FeedDataTile = ({ suggestedFeedData, totalFeedData, unit, onUpdate }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [weight, setWeight] = useState(suggestedFeedData);
  const [recirculated, setRecirculated] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const canfeed = suggestedFeedData > 1;
  const axiosPrivate = useAxiosPrivate();
  const reactorId = useSelector((state) => state.auth.reactorId);
  const [errors, setErrors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleFeedNow = async () => {
    let weightValidationErrors = "";
    weightValidationErrors += validateNumericInputWeight({
      inputValue: weight,
      inputName: "Weight",
      minValue: 1,
      maxValue: 2000,
    });
    weightValidationErrors += validateNumericInputWeight({
      inputValue: recirculated,
      inputName: "Recirculated",
      minValue: 0,
      maxValue: 500,
    });
    if (weightValidationErrors.length > 0) {
      setErrors(weightValidationErrors);
      setSnackbarMessage(weightValidationErrors);
      setSnackbarOpen(true);
      return;
    }
    const content = {};

    content.recirculated = Number(recirculated);
    content.weight = Number(weight);

    const event = {
      name: "Quick Feed",
      content: content,
      type_name: "feed",
    };

    try {
      const response = await axiosPrivate.post(`/events/${reactorId}/add`, {
        event,
      });
      if (onUpdate) onUpdate();
      dispatch(setEvents(response.data.data));
      setWeight("");
      setRecirculated("");
      setOpen(false);
    } catch (error) {
      const errorMessage = "Error submitting event";
      setErrors(errorMessage);
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
      setOpen(false);
      return;
    }
  };

  return (
    <>
      <StyledCard onClick={handleOpen} data-canfeed={canfeed.toString()}>
        <CardContent>
          <Typography variant="h5" component="div">
            {t("feedDataTile.feed")}
          </Typography>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 8, alignItems: "center" }}
          >
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <IconBox>
                  <DownloadIcon style={{ color: "#ffc107" }} />
                </IconBox>
                <Box>
                  <Typography variant="body1" component="div">
                    {suggestedFeedData}
                    {unit}
                  </Typography>
                  <Typography variant="caption" component="div">
                    {t("feedDataTile.suggestedFeed")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <IconBox>
                  <RecyclingIcon style={{ color: "#4caf50" }} />
                </IconBox>
                <Box>
                  <Typography variant="body1" component="div">
                    {totalFeedData}
                    {unit}
                  </Typography>
                  <Typography variant="caption" component="div">
                  {t("feedDataTile.totalFed")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <IconButton
            style={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleOpen}
          >
            <ArrowForwardIosIcon
              style={{ color: "#9e9e9e" }}
              fontSize="small"
            />
          </IconButton>
        </CardContent>
      </StyledCard>
      <StyledModal open={open} onClose={handleClose}>
        <ModalContent
          sx={{
            bgcolor: colors.primary[400],
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("feedDataTile.feed")}
          </Typography>
          <TextField
            type="number"
            label={t("feedDataTile.feedAmount")}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{ endAdornment: unit }}
          />
          <Button variant="contained" color="primary" onClick={handleFeedNow}>
            {t("feedDataTile.feedNow")}
          </Button>
        </ModalContent>
      </StyledModal>
    </>
  );
};

export default FeedDataTile;
