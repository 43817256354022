import axios from "axios";


export function getApiUrl() {
  return window.appConfig.REACT_APP_API_URL + "/api";
}

export function createAxiosPublic() {
  return axios.create({
    baseURL: getApiUrl(),
    headers: { "Content-Type": "application/json" },
  });
}

export function createAxiosPrivate() {
  const axiosPublic = createAxiosPublic();
  return axiosPublic.create({
    withCredentials: true,
  });
}

// Seperate API client for handling events due to Content type
export function createAxiosEvent() {
  const apiURL = getApiUrl();
  return axios.create({
    baseURL: `${apiURL}/events`,
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data" },
  })
}
