import { Snackbar } from "@mui/material";

const CustomSnackbar = ({ snackbarOpen, handleCloseSnackbar, snackbarMessage }) => (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
      // Include any additional actions if required
    />
  );

export default CustomSnackbar;
