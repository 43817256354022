import { Box } from "@mui/material";
import Header from "../../components/Header";
import { Navigate }  from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Account = () => {
  const { t } = useTranslation();

  return (
    <Box m="20px">
      <Header
        title={t('account.title')}
        subtitle={t('account.subtitle')}
      />
      <Box height="75vh">
      </Box>
      {/* <ConditionalNavigation /> */}
    </Box>
  );
};

export default Account;