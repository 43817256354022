import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../../state/reactor";
import EventWidget from "./EventWidget";
import useCacheFetch from "../../hooks/useCacheFetch";
import useInterval from "../../hooks/useInterval";
import { createAxiosPrivate } from "../../api/axios";
import { useRef } from "react";


const EventsWidget = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.reactor.events);
  const reactorId = useSelector((state) => state.auth.reactorId);
  const endpoint = `/events/${reactorId}`;
  const axiosPrivate = createAxiosPrivate();
  // const { data, loading, error, fetchData } = useCacheFetch('/user/reactors');

  useEffect(() => {
    const getData = async () => {
        try {
          const response = await axiosPrivate.get(endpoint);
          dispatch(setEvents(response.data.data));
        } catch (err) {
          console.error(err);
        }
      }
    getData();

    /* Cleanup once component unmounts to to prevent further updates to state */
  }, []);
  // const { data, loading, error, fetchData } = useCacheFetch(`/events/${reactorId}`);

  // // TODO: Test caching response of fetching data periodically
  // useInterval(
  //   () => {
  //     if (!loading) fetchData();
  //   },
  //   10000,
  //   true
  // );
  // dispatch(setEvents(data));

  if (events.length === 0) {
    return <div>No Reactor available.</div>;
  }

  return (
    <>
      {events.map(
        ({
          id,
          user_id,
          username,
          name,
          event_type,
          type_id,
          content,
          description,
          location,
          created_at,
          likes,
          comments,
          files,
        }) => (
          <EventWidget
            key={id}
            reactorId={reactorId}
            eventId={id}
            eventUserId={user_id}
            eventUserName={username}
            name={name}
            type_name={event_type.name}
            type_id={type_id}
            content={content}
            description={description}
            location={location}
            created_at={created_at}
            likes={likes}
            comments={comments}
            files={files}
          />
        )
      )}
    </>
  );
};

export default EventsWidget;
