export function validatePassword(password, t) {
  if (password.length < 8) {
    return t("validation.password.minLength");
  }
  if (password.length > 30) {
    return t("validation.password.maxLength");
  }
  if (!password.match(/[a-z]/)) {
    return t("validation.password.lowercase");
  }
  if (!password.match(/[A-Z]/)) {
    return t("validation.password.uppercase");
  }
  if (!password.match(/[0-9]/)) {
    return t("validation.password.numeric");
  }
  if (!password.match(/[!@#$%^&?*]/)) {
    return t("validation.password.special");
  }

  return "";
}

export function validateEmail(email, t) {
  /*
  Validates whether an email is a valid value
  */
  if (!email) {
    return t("validation.email.empty");
  }
  if (email.search("@") < 0) {
    return  t("validation.email.missingAt");
  }
  var recipient = email.substr(0, email.search("@"));
  var domain = email.substr(email.search("@") + 1);

  if (recipient.length < 1 || recipient.length > 64) {
    return  t("validation.email.invalidRecipient");
  }
  if (domain.length < 1 || domain.length > 253) {
    return t("validation.email.invalidDomain");
  }
  if (
    recipient.charAt(0) === "." ||
    recipient.charAt(recipient.length - 1) === "." ||
    recipient.charAt(0) === "-" ||
    recipient.charAt(recipient.length - 1) === "-" ||
    recipient.charAt(0) === "_" ||
    recipient.charAt(recipient.length - 1) === "_" ||
    recipient.charAt(0) === "+" ||
    recipient.charAt(recipient.length - 1) === "+"
  ) {
    return t("validation.email.invalidCharacter");
  }
  if (
    domain.charAt(0) === "." ||
    domain.charAt(domain.length - 1) === "." ||
    domain.charAt(0) === "-" ||
    domain.charAt(domain.length - 1) === "-" ||
    domain.charAt(0) === "_" ||
    domain.charAt(domain.length - 1) === "_" ||
    domain.charAt(0) === "+" ||
    domain.charAt(domain.length - 1) === "+"
  ) {
    return t("validation.email.invalidDomainCharacter");
  }
  if (recipient.match(/\.\./)) {
    //two consecutive dots on recipient part
    return  t("validation.email.consectutiveDots");
  }
  if (domain.match(/\.\./)) {
    //two consecutive dots on domain part
    return  t("validation.email.consectutiveDots");
  }
  if (!recipient.match(/^[A-Za-z0-9!#%&`_=\\/$'*+?^{}|~.\-" ]+$/)) {
    //invalid character on recipient part
    return  t("validation.email.invalidRecipient");
  }
  if (!domain.match(/^[A-Za-z0-9.-]+$/)) {
    //invalid character on domain part
    return  t("validation.email.invalidDomain");
  }
  if (!domain.match(/\./)) {
    return  t("validation.email.invalidTopLevelDomain");
  }
  return "";
}

export function validateComment(comment, t) {
  /*
  Validate whether an event comment is a valid value
  and does not exceed the maximum & minimum length.
  */
  const errors = [];

  if (!comment.trim()) {
    errors.push(t("validation.comment.empty"));
  }

  const minLength = 5;
  const maxLength = 500;
  if (comment.length < minLength || comment.length > maxLength) {
    errors.push(
      t("validation.comment.length", { minLength, maxLength })
    );
  }

  return errors;
}

export function validateNumericInputWeight({inputValue, inputName, minValue, maxValue, t}) {
  /*
  Validate whether the input value is a valid numeric value and if not
  returns an error message.
  */
  let errors = [];
  const inputNumber = Number(inputValue);

  // Check for disallowed characters
  if (/[^0-9.]/.test(inputValue)) {
    errors.push(
      t("validation.numericInput.nonNumeric", { inputName })
    );
  }

  if (inputValue === "e") {
    errors.push(t("validation.numericInput.nonNumeric", { inputName }));
  }

  if (inputValue === "") {
    errors.push(t("validation.numericInput.empty", { inputName }));
  }

  if (
    isNaN(inputNumber) ||
    inputNumber < minValue ||
    inputNumber > maxValue
  ) {
    errors.push(
      t("validation.numericInput.range", { inputName, minValue, maxValue })
    );
  }

  return errors;
}
