import { Box, Typography, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import DetailedEventWidget from "../widgets/DetailedEventWidget";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const DetailedEvent = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const location = useLocation();
  const { eventId } = location.state || {};
  const reactorId = useSelector((state) => state.auth.reactorId);
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <DetailedEventWidget eventId={eventId} reactorId={reactorId}/>
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="2rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DetailedEvent;
