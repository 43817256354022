import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from './i18n';

export function useSetLanguage() {
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    const preferredLanguage = localStorage.getItem("language") || language;

    if (preferredLanguage) {
      i18n.changeLanguage(preferredLanguage);
    }
  }, [language]);
}