import { useEffect, useRef } from "react";

/* 
Hook for handling periodic actions such as refresh data every x interval 
adapted from: https://stackoverflow.com/questions/59667278/react-hooks-periodic-run-useeffect

Be aware, when used with useCacheFetch or any other hook with a fallback (such as the data
stored in cache), if immediate=false then the fallback also isn't returned as the hook has not
been triggered. This is intended behaviour.
*/

const useInterval = (callback, interval, immediate = false) => {
    const callbackRef = useRef();
  
    useEffect(() => {
      callbackRef.current = callback;
    }, [callback]);
  
    useEffect(() => {
        // If immediate is true, call the callback right away
        if (immediate) {
          callbackRef.current();
        }
      
        // Continue with setting up the interval
        const handle = setInterval(() => {
          callbackRef.current();
        }, interval);
      
        // Cleanup the interval on unmount
        return () => clearInterval(handle);
      }, [interval, immediate]);
  };
  
  export default useInterval;
