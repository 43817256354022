import styled from "styled-components";

const ResponsiveBox = styled.div`
  padding: 10px;
  margin: auto;

  @media (max-width: 300px) {
    padding: 5px;
  }
`;

export default ResponsiveBox;