import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

const CustomDropDown = ({ dropDownKey, onChange, value }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [tooltipText, setTooltipText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTooltip, setOpenTooltip] = useState(false);

  const getEnglishDescription = (key) => {
    return i18n.getFixedT("en")(key);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const descriptionKey = `${dropDownKey}.${selectedValue}.description`;
    const description = getEnglishDescription(descriptionKey);
    onChange(selectedValue, description);
    setTooltipText(description);
    setAnchorEl(event.currentTarget);
    setOpenTooltip(true);

    if (isMobile) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    }
  };

  const handleMouseEnter = (event, description) => {
    if (!isMobile) {
      setTooltipText(description);
      setAnchorEl(event.currentTarget);
      setOpenTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setOpenTooltip(false);
    }
  };

  const options = Object.keys(t(dropDownKey, { returnObjects: true })).filter(
    (key) => key !== "title"
  );

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        required
        variant="standard"
        sx={{
          color: colors.grey[200],
          "&.Mui-focused": {
            color: colors.grey[200],
          },
        }}
      >
        {t(`${dropDownKey}.title`)}
      </InputLabel>
      <Select
        onChange={handleSelectChange}
        value={value}
        required
        displayEmpty={false}
        inputProps={{
          name: dropDownKey,
          id: `${dropDownKey}-select`,
        }}
        sx={{
          width: "100%",
          backgroundColor: colors.grey[300],
          color: colors.primary[900],
          borderRadius: "0.5rem",
          padding: "0.5rem 1rem",
          "& .MuiSelect-select": {
            padding: "0.2rem 0.25rem",
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
          },
        }}
        onOpen={() => setOpenTooltip(false)}
      >
        {options.map((key) => {
          const description = t(`${dropDownKey}.${key}.description`);
          return (
            <MenuItem
              key={key}
              value={key}
              onMouseEnter={(event) => handleMouseEnter(event, description)}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                if (isMobile) {
                  setTooltipText(description);
                  setAnchorEl(null);
                  setOpenTooltip(true);
                  setTimeout(() => {
                    setOpenTooltip(false);
                  }, 1500);
                }
              }}
            >
              {t(`${dropDownKey}.${key}.option`)}
            </MenuItem>
          );
        })}
      </Select>
      <Tooltip
        open={openTooltip}
        title={tooltipText}
        anchorEl={anchorEl}
        arrow
        placement="top"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 300 }}
      />
    </FormControl>
  );
};

export default CustomDropDown;
