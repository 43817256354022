import useAuth from './useAuth';
import { createAxiosPublic } from '../api/axios';

/*
TODO: Add offline refresh functionality, if user is offline, then don't throw them out of their
session immediately when they have a failed refresh. Could be done using intercepters or some
other logic?
*/

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const axiosPublic = createAxiosPublic();

    const refresh = async () => {
        try {
            const response = await axiosPublic.post('/refresh', {}, {
                withCredentials: true,
            });

            if (response.data.data.logged_in) {
                setAuth(prev => {
                    const sessionRoles = sessionStorage.getItem("user_roles");
                    const localRoles = localStorage.getItem("user_roles");
                    let userRoles = []
                    if (sessionRoles) {
                        userRoles = sessionRoles.split(",")
                    }
                    else if (userRoles) {
                        userRoles = localRoles.split(",")
                    }

                    return {
                        ...prev,
                        roles: userRoles,
                        user: sessionStorage.getItem("user") || localStorage.getItem("user"),
                        loggedIn: true,
                    }
                });
                return true;
            } else {
              localStorage.clear()
              sessionStorage.clear()
                setAuth(prev => {
                    return {
                        ...prev,
                        roles: [],
                        user: "",
                        loggedIn: false,
                    }
                });
                return false;
            }
        } catch (error) {
            console.error(error);
            localStorage.clear()
            setAuth(prev => {
                return {
                    ...prev,
                    roles: [],
                    user: "",
                    loggedIn: false,
                }
            });
            return false;
        }
    };

    return refresh;
};

export default useRefreshToken;
