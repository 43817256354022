import { Box } from "@mui/material";
import CSVDisplay from "./ui-elements/CSVDisplay";

const EventFile = ({ src, alt, filename, size = "500px" }) => {
  const fileType = filename.split('.').pop().toLowerCase();

  return (
    <Box width={size} height={size}>
      {fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg' || fileType === 'heic' ? (
        <img src={src} alt={alt} style={{ width: "100%", height: "auto" }} />
      ) : fileType === 'xlsb' || fileType === 'xlsx' ? (
        <Box style={{ width: "100%", height: "auto", padding: "20px" }}>
          Excel File: {alt}
        </Box>
      ) : fileType === 'csv' ? (
        <Box style={{ width: "100%", height: "auto", padding: "20px" }}>
          <CSVDisplay src={src}/>
        </Box>
      ) : (
        <Box style={{ width: "100%", height: "auto", padding: "20px" }}>
          Unknown File Type
        </Box>
      )}
    </Box>
  );
};

export default EventFile;