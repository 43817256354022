import { tokens } from "../../../theme";
import { InputBase } from "@mui/material";
import { useTheme } from "@mui/material";

const InputField = ({ placeholder, onChange, value }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <InputBase
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        sx={{
          width: "100%",
          backgroundColor: colors.grey[300],
          color: colors.grey[900],
          borderRadius: "2rem",
          padding: "1rem 2rem",
          fontSize: '16px',
        }}
      />
    );
  };

export default InputField;
