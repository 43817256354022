import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const CustomTagsManager = ({ chipData, setChipData, setEnglishTags }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [customTag, setCustomTag] = useState('');
  const [weight, setWeight] = useState('');
  const [weightError, setWeightError] = useState(false);

  const createPredefinedTags = (keys) => {
    return keys.map((key, index) => ({
      key: index,
      label: t(`customTagsManager.tagNames.${key}`),
      index: `customTagsManager.tagNames.${key}`
    }));
  };

  const predefinedTags = createPredefinedTags(['paper', 'palmLeaves', 'grass']);
  // increment key if predefined tags > 3
  const [customKey, setCustomKey] = useState(3);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    setEnglishTags((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (tag) => {
    setAnchorEl(null);
    setSelectedTag(tag);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedTag(null);
    setCustomTag('');
    setWeight('');
    setWeightError(false);
  };

  const handleWeightChange = (e) => {
    const value = e.target.value;
    // Regular expression to match a valid integer or float
    const validNumber = /^-?\d*\.?\d*$/;
    if (validNumber.test(value)) {
      setWeight(value);
      setWeightError(false);
    } else {
      setWeightError(true);
    }
  };

  const handleAddTag = () => {
    if (weight.trim() === '' || isNaN(weight)) {
      setWeightError(true);
      return;
    }

    let newChip;
    let englishTag;
    if (selectedTag === t('customTagsManager.tagNames.other')) {
      newChip = { key: customKey, label: `${weight}kg ${customTag}` };
      englishTag = newChip.label;
      setCustomKey((prevKey) => prevKey + 1);
    } else {
      newChip = { key: selectedTag.key, label: `${weight}kg ${selectedTag.label}` };
      englishTag = `${weight}kg ${i18n.getFixedT('en')(`${selectedTag.index}`)}`;
    }

    setChipData((chips) => [...chips, newChip]);
    setEnglishTags((chips) => [...chips, { key: newChip.key, label: englishTag }]);
    handleDialogClose();
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: colors.grey[200] }}
      >
        {t('customTagsManager.buttons.addTag')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {predefinedTags.map((tag) => (
          <MenuItem
            key={tag.key}
            onClick={() => handleMenuItemClick(tag)}
            disabled={chipData.some((chip) => chip.key === tag.key)}
          >
            {tag.label}
          </MenuItem>
        ))}
        <MenuItem
          key="other"
          onClick={() => handleMenuItemClick(t('customTagsManager.tagNames.other'))}
        >
          {t('customTagsManager.tagNames.other')}
        </MenuItem>
      </Menu>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {chipData.map((data) => (
          <ListItem key={data.key}>
            <Chip
              label={data.label}
              onDelete={handleDelete(data)}
            />
          </ListItem>
        ))}
      </Paper>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        fullScreen={fullScreen}
      >
        <DialogTitle>
          {selectedTag === t('customTagsManager.tagNames.other') 
            ? t('customTagsManager.text.customTagTitle') 
            : selectedTag?.label}
        </DialogTitle>
        <DialogContent>
          {selectedTag === t('customTagsManager.tagNames.other') && (
            <TextField
              margin="dense"
              label={t('customTagsManager.text.customTagLabel')}
              fullWidth
              value={customTag}
              onChange={(e) => setCustomTag(e.target.value)}
              InputLabelProps={{
                style: { color: colors.grey[100], fontSize: '16px' },
              }}
              InputProps={{
                style: { color: colors.grey[100], fontSize: '16px' },
              }}
            />
          )}
          <TextField
            margin="dense"
            label={t('customTagsManager.text.weightLabel')}
            fullWidth
            value={weight}
            onChange={handleWeightChange}
            error={weightError}
            helperText={weightError ? t('customTagsManager.text.invalidWeight') : ''}
            InputLabelProps={{
              style: { color: colors.grey[100], fontSize: '16px' },
            }}
            InputProps={{
              style: { color: colors.grey[100], fontSize: '16px' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: colors.grey[100] }}>{t('customTagsManager.buttons.cancelTag')}</Button>
          <Button onClick={handleAddTag} sx={{ color: colors.grey[100]}}>{t('customTagsManager.buttons.confirmAddTag')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomTagsManager;

