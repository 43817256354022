import { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
} from "@mui/material";
import { tokens } from "../theme";
import localforage from "localforage";
import useCacheFetch from "../hooks/useCacheFetch";
import useInterval from "../hooks/useInterval";
import { useDispatch, useSelector } from "react-redux";
import { setReactor, setReactors } from "../state/auth";
import { useTranslation } from 'react-i18next';

const ReactorPicker = ({ closeModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const reactors = useSelector((state) => state.auth.reactors);
  const reactorId = useSelector((state) => state.auth.reactorId);
  const [error, setError] = useState("");
  const endpoint_reactors = "/user/reactors";
  const fetchOptions = {};
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function getReactorContext() {
      const reactorContext = await localforage.getItem('reactor_context');
      if (reactorContext) {
        setReactor({ reactorContext })
      }
    }
    getReactorContext();
  }, []);

  const { data, loading, hasError, fetchData } = useCacheFetch(
    endpoint_reactors,
    fetchOptions
  );
  useInterval(() => {
    if (!loading) fetchData();
  }, 10000, true);

  useEffect(() => {
    if (loading) {
    } else if (hasError) {
      setError("Something went wrong.", hasError);
    } else {
      if (data && data.data.reactors) {
        dispatch(setReactors(data.data));
      }
    }
  }, [data, loading, hasError]);

  const handleReactorSelect = (event) => {
    const reactor = reactors.find((r) => r.id === event.target.value);
    localforage.setItem("reactor_context", JSON.stringify(reactor));
    setReactor({ reactor });
    dispatch(
      setReactor({
        reactorId: reactor.id,
      })
    );
    closeModal();
  };

  return (
    <FormControl fullWidth sx={{ color: colors.grey[100] }}>
      <InputLabel sx={{ color: colors.grey[100] }}>Reactor</InputLabel>
      {reactors.length > 0 ? (
        <Select
          value={reactorId || ""}
          onChange={handleReactorSelect}
          label="Reactor"
          sx={{
            color: colors.grey[100],
            "& .MuiSelect-icon": { color: colors.grey[100] },
          }}
        >
          {reactors.map((reactor) => (
            <MenuItem
              key={reactor.id}
              value={reactor.id}
              sx={{ color: colors.grey[100] }}
            >
              {reactor.name} - {reactor.permission}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <div>Loading...</div>
      )}
    </FormControl>
  );
}

export default ReactorPicker;
