import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import i18n from "./localisation/i18n";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { ReactorProvider } from "./context/ReactorProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import authReducer from "./state/auth";
import reactorReducer from "./state/reactor";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { OnlineStatusProvider } from "./context/useOnlineStatus";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

fetch('/config/config.json')
  .then((response) => response.json())
  .then((config) => {
    // Store the configuration in a global variable
    window.appConfig = config;

    const rootReducer = combineReducers({
      auth: authReducer,
      reactor: reactorReducer,
    });
    const persistConfig = { key: "auth", storage, version: 1, whitelist: ["auth"] };
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = configureStore({
      reducer: persistedReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }),
    });

    /* Disable React Dev Tools if in production */
    if (window.appConfig.ENVIRONMENT === "production") {
      disableReactDevTools();
    }

    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <OnlineStatusProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistStore(store)}>
              <BrowserRouter>
                <AuthProvider>
                  <ReactorProvider>
                    <Routes>
                      <Route path="/*" element={<App />} />
                    </Routes>
                  </ReactorProvider>
                </AuthProvider>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </OnlineStatusProvider>
      </React.StrictMode>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    // Configure and register the service worker
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        // Code to handle the update process
        // Inform the user that a new version of the app is available
        console.log("New version of the app is available.");

        // Optionally, you can prompt the user to reload the page to update
        if (window.confirm("New version available. Do you want to update now?")) {
          window.location.reload();
        }
      },

      // Code to handle successful registration
      onSuccess: (registration) => {
        console.log("Content is now available offline!");
      },
      // Adjust these to use window.appConfig instead of window.injectedEnv
      applicationServerPublicKey: window.appConfig.REACT_APP_APPLICATION_SERVER_PUBLIC_KEY,
      apiEndpoint: window.appConfig.REACT_APP_API_URL + "/api",
    });
    // Synchronise service worker config with main thread by posting config
    // to service worker
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'SET_CONFIG',
        config: window.appConfig,
      });
    }

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

  })
  .catch((error) => {
    console.error("Failed to load configuration:", error);
    // Consider adding fallback behavior or error handling here
  });
