/*
Entry Reactor for User

Checks if user already has a selected Reactor context, if they
do not then it makes a request every interval (including on
render) until it discovers a reactor and then selects it.
*/

import { useEffect, useState } from "react";
import localforage from "localforage";
import useCacheFetch from "../hooks/useCacheFetch";
import useInterval from "../hooks/useInterval";
import { useDispatch, useSelector } from "react-redux";
import { setReactor, setReactors } from "../state/auth";
import { useTranslation } from 'react-i18next';


const EntryReactor = () => {
  const [error, setError] = useState("");
  const endpoint_reactors = "/user/reactors";
  const reactorId = useSelector((state) => state.auth.reactorId);
  const fetchOptions = {};
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function getReactorContext() {
      const reactorContext = await localforage.getItem('reactor_context');
      if (reactorContext && !reactorId) {
        dispatch(setReactor(JSON.parse(reactorContext)));
        setReactor({ reactorContext })
      }
    }
    getReactorContext();
  }, [dispatch, reactorId]);

  const { data, loading, hasError, fetchData } = useCacheFetch(
    endpoint_reactors,
    fetchOptions
  );
  useInterval(() => {
    if (!loading && !reactorId) fetchData();
  }, 5000, true);

  useEffect(() => {
    if (!loading && hasError) {
      setError(t('entryReactor.errorMessage'));
    } else {
      if (data && !reactorId) {
        dispatch(setReactors(data.data))
        const base_reactor = data.data.reactors[0];
        localforage.setItem("reactor_context", JSON.stringify(base_reactor));
        dispatch(setReactor({
          reactorId: base_reactor.id,
        }));
        window.location.reload();
      }
    }
  }, [data, loading, hasError]);

return 
};

export default EntryReactor;