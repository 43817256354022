import React from "react";
import {
  Button,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";

const PostButton = ({ disabled, onClick, buttonText }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <Button
        disabled={disabled}
        onClick={onClick}
        sx={{
          "&:disabled": {
            backgroundColor: colors.primary[200],
            color: colors.primary[400],
          },
          color: colors.greenAccent[500],
          bgcolor: colors.greenAccent[100],
          borderRadius: "3rem",
          "&:hover": {
            fontWeight: "bold",
          },
        }}
      >
        {buttonText}
      </Button>
    );
  };

export default PostButton;
