import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Modal,
  Typography,
  MenuItem,
  Slide,
  Button,
  Snackbar,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import { useNavigate } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import ReactorPicker from "../../components/ReactorPicker";
import { useSelector } from "react-redux";
import useOnlineStatus from "../../context/useOnlineStatus";
import { useTranslation } from 'react-i18next';


const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const [reloadKey, setReloadKey] = useState(0);
  const userId = useSelector((state) => state.auth.user_id);
  const isOnline = useOnlineStatus();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const prevIsOnlineRef = useRef();
  const { t } = useTranslation();
  const settings = ["Profile", "Account", "Colleagues", "Logout"].map(setting => t(`settingsMenu.${setting.toLowerCase()}`));

  const signOut = async () => {
    await logout();
    navigate("/login");
  };


  useEffect(() => {
    if (
      prevIsOnlineRef.current !== undefined &&
      prevIsOnlineRef.current !== isOnline
    ) {
      const message = isOnline
        ? "You're back online!"
        : "Your connection is unstable!";
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }
    prevIsOnlineRef.current = isOnline;
  }, [isOnline]);

  const handleMenuItemClick = (setting) => {
    if (setting.toLowerCase() === t('settingsMenu.profile').toLowerCase()) navigate(`/profile/${userId}`);
    else if (setting.toLowerCase() === t('settingsMenu.account').toLowerCase()) navigate("/account");
    else if (setting.toLowerCase() === t('settingsMenu.colleagues').toLowerCase()) navigate("/colleagues");
    else if (setting.toLowerCase() === t('settingsMenu.logout').toLowerCase()) signOut();
    setModalOpen(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (prevIsOnlineRef.current !== undefined && prevIsOnlineRef.current !== isOnline) {
      const message = isOnline ? "You're back online!" : "Your connection is unstable!";
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    }
    prevIsOnlineRef.current = isOnline;
  }, [isOnline]);

  const modalContent = (
    <Slide direction="up" in={modalOpen} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <Button onClick={() => setModalOpen(false)}>
          <ArrowDropDownIcon
            style={{ cursor: "pointer", color: colors.grey[100] }}
          />
        </Button>
        {settings.map((setting) => (
          <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
            <Typography textAlign="center" color={colors.grey[100]}>
              {setting}
            </Typography>
          </MenuItem>
        ))}
        <ReactorPicker closeModal={closeModal} />
      </Box>
    </Slide>
  );

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box display="flex">
        <IconButton>
          {isOnline ? <WifiIcon /> : <WifiOffIcon sx={{ color: "red" }} />}
        </IconButton>
      </Box>
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      ></Box>
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={() => navigate("/notifications")}>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => navigate("/settings")}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => setModalOpen(true)} sx={{ p: 0 }}>
          <PersonOutlinedIcon />
        </IconButton>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="menu-modal"
          aria-describedby="fullscreen-slide-menu"
          slotProps={{
            backdrop: {
              sx: { backgroundColor: colors.primary[400] },
            },
          }}
        >
          {modalContent}
        </Modal>
      </Box>
      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        // action={/* Optional: actions you might want to add, like a close button */}
      />
    </Box>
  );
};

export default Topbar;
