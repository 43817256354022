import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import UserWidget from "../widgets/UserWidget";
import MyEventWidget from "../widgets/MyEventWidget";
import EventsWidget from "../widgets/EventsWidget";
import ColleagueListWidget from "../widgets/ColleagueListWidget";
import useOnlineStatus from "../../context/useOnlineStatus";
import { tokens } from "../../theme";
import { useTranslation } from 'react-i18next';

const Reactor = () => {
  const { t } = useTranslation();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const id = useSelector((state) => state.auth.user_id);
  const reactorId = useSelector((state) => state.auth.reactorId);
  const isOnline = useOnlineStatus();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Typography
          color={colors.primary[300]}
          sx={{fontSize: 28,
        }}
        >
          {isOnline ? "" : t('reactor.offlineWarning')}
        </Typography>
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          {/* <UserWidget userId={id} /> */}
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MyEventWidget />
          <EventsWidget userId={id} key={reactorId} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="2rem 0" />
            {/* <ColleagueListWidget userId={id} /> */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Reactor;
