import { Box, Grid, Paper } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import Header from "../../components/Header";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setStats } from "../../state/reactor";
import EntryReactor from "../../components/EntryReactor";
import { useDispatch, useSelector } from "react-redux";
import { createAxiosPrivate } from "../../api/axios";
import CapsuleChart from "../../components/CapsuleChart";
import DataPointTile from "../../components/DataTile";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import BoltIcon from "@mui/icons-material/Bolt";
import ScienceIcon from "@mui/icons-material/Science";
import HarvestDataTile from "../../components/HarvestDataTile";
import FeedDataTile from "../../components/FeedDataTile";
import ReactorFillGauge from "../../components/ReactorFillGauge";
import ResponsiveBox from "../../components/ResponsiveBox";
import useWebSocketInterceptor from "../../hooks/useWebSocketInterceptor";
import useRefreshToken from "../../hooks/useRefreshToken";

const Home = () => {
  const { t } = useTranslation();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const reactorStats = useSelector((state) => state.reactor.stats);
  const reactorId = useSelector((state) => state.auth.reactorId);
  const reactors = useSelector((state) => state.auth.reactors);
  const endpoint = `/calculate/reactor/${reactorId}`;
  const axiosPrivate = createAxiosPrivate();
  const dispatch = useDispatch();
  const reactorName = reactors.find((reactor) => reactor.id === reactorId)?.name || "Unselected";
  const bottomBarHeight = "4.5rem";
  const initialWebsocketData = {
    temperature_center: "0",
    ph_center: "0",
    oxidation_reduction_potential_center: "0"
  };
  const [websocketData, setWebsocketData] = useState(initialWebsocketData);
  const previousReactorId = useRef(reactorId);
  const { socket, subscribe, initializeSocket } = useWebSocketInterceptor({ reactorId });
  const refresh = useRefreshToken();

  // Handle WebSocket messages
  useEffect(() => {
    if (socket) {
      socket.on("message", (data) => {
        if (data.entity) {
          setWebsocketData(prevData => ({
            ...prevData,
            [data.entity.toLowerCase()]: data.value
          }));
        } else {
          console.warn("Received data without 'entity' field:", data);
        }
      });

      return () => {
        socket.off("message");
      };
    }
  }, [socket]);

  // Subscription logic if user changes reactor context
  useEffect(() => {
    const handleReactorChange = async () => {
      if (previousReactorId.current !== reactorId) {
        if (socket) {
          socket.emit("client-disconnect");
          socket.close();
          console.log("WebSocket disconnected by client");
          setWebsocketData(initialWebsocketData);
          previousReactorId.current = reactorId;
          // Initialize a new socket connection with the new reactorId
          const tokenRefreshed = await refresh();
          if (tokenRefreshed) {
            initializeSocket(reactorId);
          } else {
            console.error("Failed to refresh token");
          }
        }
      }
    };

    handleReactorChange();
  }, [reactorId, socket]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosPrivate.get(endpoint);
        dispatch(setStats(response.data.data));
      } catch (err) {
        console.error(err);
      }
    };
    getData();
  }, [updateTrigger, reactorId]);

  useEffect(() => {
    return () => {
      if (socket) {
        socket.emit("client-disconnect");
        socket.close();
        console.log("WebSocket disconnected by client");
      }
    };
  }, [socket]);

  if (reactorStats.length === 0) {
    return (<div>No Reactor available. <EntryReactor/></div>);
  }
 // TODO: Handle websocket data
  return (
    <Box m="20px" pb={bottomBarHeight} sx={{ pb: bottomBarHeight }}>
      <EntryReactor/>
      <Header subtitle={t("home.subtitle", { reactorName })} />
      <ResponsiveBox>
        <Grid container spacing={3} justifyContent="center">
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ReactorFillGauge
              min={reactorStats.minimum_fill_weight}
              max={reactorStats.maximum_fill_weight}
              target={reactorStats.target_fill_weight}
              level={reactorStats.current_fill_weight}
              unit="kg"
            />
          </Grid>
          <Grid item xs={12}>
            <CapsuleChart
              data={reactorStats.process_lifecycle}
              header={t("home.bioProcessLifeCycle")}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="space-between">
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <DataPointTile
                header={t("home.temperature")}
                data={websocketData.temperature_center}
                valueUnits="°C"
                IconComponent={ThermostatIcon}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <DataPointTile
                header="pH"
                data={websocketData.ph_center}
                valueUnits=""
                IconComponent={ScienceIcon}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <DataPointTile
                header="ORP"
                data={websocketData.oxidation_reduction_potential_center}
                valueUnits="mV"
                IconComponent={BoltIcon}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <HarvestDataTile
              suggestedHarvestData={reactorStats.suggested_harvest_weight}
              unit="kg"
              totalHarvestedData={reactorStats.total_feed}
              onUpdate={() => setUpdateTrigger(!updateTrigger)}
            />
          </Grid>
          <Grid item xs={12}>
            <FeedDataTile
              suggestedFeedData={reactorStats.suggested_feed_weight}
              unit="kg"
              totalFeedData={reactorStats.total_harvest}
              onUpdate={() => setUpdateTrigger(!updateTrigger)}
            />
          </Grid>
        </Grid>
      </ResponsiveBox>
    </Box>
  );
};

export default Home;
