const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  if ('serviceWorker' in navigator) {
    window.addEventListener("load", () => {
      const swUrl = `${window.appConfig.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log("New content is available; please refresh.");
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
              notifyUserAboutUpdate(registration.waiting);
            } else {
              console.log("Content is cached for offline use :).");
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } }).then(response => {
    const contentType = response.headers.get('content-type');
    if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    } else {
      registerValidSW(swUrl, config);
    }
  }).catch(() => {
    console.log('No internet connection found. App is running in offline mode.');
  });
}

function notifyUserAboutUpdate(worker) {
  // Notify user of update, and ask them to refresh
  // eslint-disable-next-line no-restricted-globals
  if (confirm("New version available! Do you want to update?")) {
    worker.postMessage({type: 'SKIP_WAITING'});
    window.location.reload();
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

// [PUSH NOTIFICATION SPECIFIC FUNCTIONS]
function requestPushNotificationPermission(registration) {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      subscribeUser(registration);
    }
  });
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function updateSubscriptionOnServer(subscription, apiEndpoint, userId) {
  // TODO: Add registration post-login and include userId
  const subscriptionApiEndpoint = `${apiEndpoint}/notification/pushsubscription/add`;
  return fetch(subscriptionApiEndpoint, {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subscription_json:subscription,
      userId: userId,
    }),
  });
}

function subscribeUser(swRegistration, userId) {
  const applicationServerKey = window.appConfig.REACT_APP_APPLICATION_SERVER_PUBLIC_KEY;
  const applicationServerPublicKey = urlB64ToUint8Array(applicationServerKey);

  // Check if the user is already subscribed
  swRegistration.pushManager.getSubscription().then(subscription => {
    if (subscription) {
      console.log('User is already subscribed.');
      return;
    }

    // Subscribe the user
    swRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: applicationServerPublicKey
    })
    .then(subscription => {
      console.log("User is subscribed.");
      const apiEndpoint = window.appConfig.REACT_APP_API_URL + "/api";
      updateSubscriptionOnServer(subscription, apiEndpoint, userId);
    })
    .catch(err => {
      console.log('Failed to subscribe the user: ', err);
    });
  });
}

export { subscribeUser };