import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import LanguageSelector from "../../components/LanguageSelector";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

const Settings = () => {
  const { t } = useTranslation();

  return (
    <Box m="20px">
      <Header title={t("settings.title")} subtitle={t("settings.subtitle")} />
      <Typography variant="body1" gutterBottom>
        {t("settings.languageSelectorIntro")}
      </Typography>
      <Box height="75vh">
        <LanguageSelector />
      </Box>
    </Box>
  );
};

export default Settings;
