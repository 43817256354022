import React, { useRef, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../state/auth";
import { createAxiosPublic } from "../api/axios";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { subscribeUser } from "../serviceWorkerRegistration";
import { useTranslation } from "react-i18next";

const endpoint = "/login";

const Login = () => {
  const axiosPublic = createAxiosPublic();
  const { setAuth } = useAuth();
  const language = useSelector((state) => state.auth.language);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const userRef = useRef();
  const errRef = useRef();
  const pwdRef = useRef();

  const [user, setUser] = useState("");
  const [persist, setPersist] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  // Function for granting push notifications permissions
  const requestNotificationPermission = async (userId) => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");

      // Check if service worker is ready and then subscribe to push notifications
      const swRegistration = await navigator.serviceWorker.ready;
      subscribeUser(swRegistration, userId);
    }
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
    /* If user item is set, redirect user to root */
    const storedUser = localStorage.getItem("user");
    if (storedUser && storedUser !== "") {
      navigate(from, { replace: true });
    }
  }, [user, pwd, navigate, from]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPublic.post(
        endpoint,
        JSON.stringify({ username: user, password: pwd }),
        {
          withCredentials: true,
        }
      );
      const payload = response.data.data;
      const roles = payload.roles.split(",");
      const loggedIn = payload.logged_in;
      const userId = payload.user_id;
      const language = payload.language;
      localStorage.setItem("user_roles", roles);
      localStorage.setItem("user", user);
      localStorage.setItem("user_id", userId);
      localStorage.setItem("language", language);

      if (loggedIn) {
        dispatch(setLogin(payload));
      }
      setAuth({ user, loggedIn, roles });
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
      requestNotificationPermission(userId);
      sessionStorage.setItem("persist", true);
      sessionStorage.setItem("user_roles", roles);
      sessionStorage.setItem("user", user);
      sessionStorage.setItem("user_id", userId);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("The username and/or password are incorrect");
      } else if (err.response?.status === 403) {
        setErrMsg("User is not activated");
      } else if (err.response?.status === 404) {
        setErrMsg("User resource is missing, please try again later");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const handleChangeUser = (e) => {
    setUser(e.target.value);
    setErrMsg("");
  };

  const handleChangePwd = (e) => {
    setPwd(e.target.value);
    setErrMsg("");
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {t(errMsg)}
        </p>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            {t("login.signIn")}
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("login.usernameLabel")}
              inputRef={userRef}
              autoComplete="username"
              onChange={handleChangeUser}
              value={user}
              InputProps={{
                style: {
                  color: colors.greenAccent[600],
                  backgroundColor: colors.primary[400],
                  fontSize: '16px',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("login.passwordLabel")}
              type="password"
              inputRef={pwdRef}
              autoComplete="current-password"
              onChange={handleChangePwd}
              value={pwd}
              InputProps={{
                style: {
                  color: colors.greenAccent[600],
                  backgroundColor: colors.primary[400],
                  fontSize: '16px',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
            >
              {t("login.signInButton")}
            </Button>
            <div className="persistCheck">
              <input
                type="checkbox"
                id="persist"
                onChange={togglePersist}
                checked={persist}
              />
              <label htmlFor="persist">{t('login.trustDevice')}</label>
            </div>
          </form>
          <Typography component="h2" variant="h5">
            {t("login.forgotPassword")}
            <br />
            <Link to="/request_reset">{t("login.forgotPasswordButton")}</Link>
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
