/*
Show cached data whilst attempting update (Cache then network approach)

This hook uses the fetch method as this can be accessed by the serviceWorker. Fetch does not support
intercepters natively. Therefore this functionality is handled here. Data is loaded from cache whilst
fresh data is fetched asychronously from network. When fresh data is returned the response is updated
and the fresh data is then cached.

This hook is designed for using GET requests only as others should be handled differently.
*/
import { useState, useEffect, useCallback } from 'react';
import useRefreshToken from './useRefreshToken';
import { getApiUrl } from '../api/axios';

// TODO: Adjust to use axios (logical seperation)

const useCacheFetch = (endpoint, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const refresh = useRefreshToken();
  const BASE_URL = getApiUrl();
  const url = BASE_URL.concat(endpoint);

  const checkStatus = async (response) => {
    if (!response.ok) {
      /* Check if Token has expired (401 response), attempt to refresh it */
      if (response.status === 401) {
        const didRefresh = await refresh();
        if (didRefresh) {
          /* If token was refreshed, retry the original fetch */
          const retryOptions = { ...options, credentials: 'include', headers: { "Content-Type": "application/json" } };
          return fetch(url, retryOptions);
        }
        throw new Error('Authentication failed');
      }
      throw new Error('Network response was not ok');
    }
    return response;
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
        /* Try to get a cached response first */
        const cachedResponse = await caches.match(url);
        if (cachedResponse) {
          const cachedData = await cachedResponse.json();
          setData(cachedData);
        }
  
        /* Prepare fetch options, omitting the body for GET requests */
        const method = (options.method || 'GET').toUpperCase();
        const fetchOptions = {
          ...options,
          method,
          credentials: 'include',
          headers: { ...options.headers, "Content-Type": "application/json" },
        };
        if (method === 'GET' || method === 'HEAD') {
          delete fetchOptions.body;
        } else if (fetchOptions.body && typeof fetchOptions.body === 'object') {
          fetchOptions.body = JSON.stringify(fetchOptions.body);
        }
  
        /* Perform the network request */
        let response = await fetch(url, fetchOptions);
        response = await checkStatus(response);
  
        /* Process the response if it's not the same as what's already in state */
        const networkData = await response.json();
        if (JSON.stringify(data) !== JSON.stringify(networkData)) {
            setData(networkData);
            const cache = await caches.open('fetched-data');
            cache.put(url, response.clone());
          
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }, [url, options, refresh, data]);


  return { data, loading, error, fetchData };
};

export default useCacheFetch;
