import Dropzone from "react-dropzone";
import {
  Typography,
  Box,
  IconButton,
  useTheme,
} from "@mui/material";
import {
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";
import { tokens } from "../../../theme";
import FlexBetween from "../../FlexBetween";
import { useTranslation } from 'react-i18next';

const FileSelector = ({ isFile, onFileChange, file, setIsFile }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const handleDelete = () => {
    onFileChange(null);
    setIsFile(false);
  };

  return (
    isFile && (
      <Box
        border={`1px solid ${colors.grey[600]}`}
        borderRadius="5px"
        mt="1rem"
        p="1rem"
      >
        <Dropzone
          accept={{
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'application/vnd.ms-excel': ['.csv'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel.sheet.binary.macroEnabled.12': ['.xlsb'],
            'image/heic': ['.heic']
          }}
          multiple={false}
          onDrop={onFileChange}
        >
          {({ getRootProps, getInputProps }) => (
            <FlexBetween>
              <Box
                {...getRootProps()}
                border={`2px dashed ${colors.grey[600]}`}
                p="1rem"
                width="100%"
                sx={{ "&:hover": { cursor: "pointer" } }}
              >
                <input {...getInputProps()} />
                {!file ? (
                  <Typography>{t('fileSelector.addFile')}</Typography>
                ) : (
                  <FlexBetween>
                    <Typography>{file.name}</Typography>
                    <EditOutlined />
                  </FlexBetween>
                )}
              </Box>
              {file && (
                <IconButton
                  onClick={handleDelete}
                  sx={{ width: "15%" }}
                >
                  <DeleteOutlined />
                </IconButton>
              )}
            </FlexBetween>
          )}
        </Dropzone>
      </Box>
    )
  );
};

export default FileSelector;
