import * as React from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import { useTheme } from "@mui/material";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokens } from "../../theme";
import { useTranslation } from 'react-i18next';

export default function BottomBar() {
  const [value, setValue] = useState("home");
  const navigate = useNavigate();
  const reactorId = useSelector((state) => state.auth.reactorId);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 10,
        "& .Mui-selected": {
          color: colors.greenAccent[500],
          fontWeight: 'bold',
          '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
            color: colors.greenAccent[500],
          }
        },
      }}
    >
      <BottomNavigation
        showLabels={false}
        value={value}
        onChange={handleChange}
        sx={{
          bgcolor: colors.primary[400],
          height: "4.5rem",
        }}
      >
        <BottomNavigationAction
          label={t('navigation.home')}
          value="home"
          onClick={() => navigate("/")}
          icon={<HomeIcon />}
        />
        <BottomNavigationAction
          label={t('navigation.reactor')}
          value="reactor"
          onClick={() => navigate("/reactor/" + reactorId)}
          icon={<DeleteSweepIcon />}
        />
        <BottomNavigationAction
          label={t('navigation.alerts')}
          value="alerts"
          onClick={() => navigate("/alerts")}
          icon={<LocationOnIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}
