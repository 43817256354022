import { createAxiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const { auth } = useAuth();
  const axiosPrivate = createAxiosPrivate();

  useEffect(() => {
    /* Add request interceptor that current does nothing to outgoing requests */
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => Promise.reject(error)
    );

    /* Intercept repsonses and check for status 401 implying expired access token and request new token if so */
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          await refresh()
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    /* Cleanup interceptors to prevent when component using hooks unmounts to prevent memory leaks */
    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
